import React, { useEffect, useState } from "react";
import CustomDropdown from "../components/Dropdown";
import { IoMdClose } from "react-icons/io";
import axios from "axios";

// Define types for dropdown options
interface Option {
  label: string;
  value: string | number;
}
interface UploadDocModalProps {
  isOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  selectedInvestId: number;
}

const UploadDocModal = ({
  isOpen,
  title,
  setIsModalOpen,
  selectedInvestId,
}: // showApiFetch,
UploadDocModalProps) => {
  // const dispatch = useDispatch();
  // Define state for dropdown values
  const [subTitle, setSubTitle] = useState("");
  const [year, setYear] = useState("");
  const [quarter, setQuarter] = useState("");
  const [description, setDescription] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  // const [isModalOpen, setIsModalOpen] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  console.log("showSuccess", showSuccess);
  // console.log("isModalOpen", isModalOpen);
  // Define state for errors
  const [errors, setErrors] = useState({
    subTitle: "",
    year: "",
    quarter: "",
    description: "",
    files: "",
  });
  // console.log("showApiFetch ==>", showApiFetch);

  // Define options for the dropdowns
  const subTitleOptions: Option[] = [
    { label: "None", value: "None" },
    { label: "Reports", value: "Reports" },
    { label: "Quarterly Results", value: "Quarterly Results" },
    { label: "Annual Reports", value: "Annual Reports" },
    { label: "Code of Conduct", value: "Code of Conduct" },
    { label: "Policies", value: "Policies" },
    { label: "List Committee", value: "List Committee" },
    { label: "Annual CR", value: "Annual CR" },
  ];

  // Handle dropdown changes
  const handleSubTitleChange = (option: any) => {
    setSubTitle(option.value as string);
    setErrors({ ...errors, subTitle: "" }); // Reset error message
  };

  const yearOptions: Option[] = [
    { label: "None", value: "None" },
    { label: "2014-15", value: "2014-15" },
    { label: "2015-16", value: "2015-16" },
    { label: "2016-17", value: "2016-17" },
    { label: "2017-18", value: "2017-18" },
    { label: "2018-19", value: "2018-19" },
    { label: "2019-20", value: "2019-20" },
    { label: "2020-21", value: "2020-21" },
    { label: "2021-22", value: "2021-22" },
    { label: "2022-23", value: "2022-23" },
    { label: "2023-24", value: "2023-24" },
    { label: "2024-25", value: "2024-25" },
  ];

  // const handleYearChange = (event: any) => {
  //   setYear(event.target.value);
  // };

  const handleYearChange = (option: any) => {
    setYear(option.value as string);
    setErrors({ ...errors, year: "" }); // Reset error message
  };

  const quarterOptions: Option[] = [
    { label: "None", value: "None" },
    { label: "Quarter 1", value: "Quarter 1" },
    { label: "Quarter 2", value: "Quarter 2" },
    { label: "Quarter 3", value: "Quarter 3" },
    { label: "Quarter 4", value: "Quarter 4" },
  ];

  // const handleQuarterChange = (event: any) => {
  //   setQuarter(event.target.value);
  // };
  const handleQuarterChange = (option: any) => {
    setQuarter(option.value as string);
    setErrors({ ...errors, quarter: "" }); // Reset error message
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      // Replace the previously uploaded files with the new selection
      setUploadedFiles(Array.from(files));
      setErrors({ ...errors, files: "" }); // Reset error message
    }
  };

  // Validation function
  const validateForm = () => {
    let isValid = true;
    let newErrors = { ...errors };

    if (!subTitle) {
      newErrors.subTitle = "Please select a subtitle.";
      isValid = false;
    }

    if (!year) {
      newErrors.year = "Please select a year.";
      isValid = false;
    }

    if (!quarter) {
      newErrors.quarter = "Please select a quarter.";
      isValid = false;
    }

    if (uploadedFiles.length === 0) {
      newErrors.files = "Please upload at least one document.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        const formData = new FormData();
        formData.append("investId", selectedInvestId.toString());
        formData.append("title", title);
        formData.append("subtitle", subTitle);
        formData.append("years", year);
        formData.append("quarter", quarter);
        formData.append("description", description);
        uploadedFiles.forEach((file) => {
          console.log(file);
          formData.append("fileUrl", file);
        });
        const response = await axios.post(
          // {* local link *}
          // `http://localhost:3001/api/create`,

          // {* live link *}
          `https://gllfl.com:3001/api/create`,
          formData
        );

        setSelectedData(response.data);
        console.log("Form submitted successfully:", response.data);

        if (response.data.status === "success") {
          setSelectedData(response.data.data);
          setShowSuccess(true);
          setIsModalOpen(false);
          window.location.reload(); // for refresh the browser
          setTimeout(() => {
            setIsModalOpen(false);
          }, 3000);
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            files: response.data.message || "An error occurred during upload.",
          }));
        }
      } catch (error) {
        console.error("Error uploading document:", error);
        setErrors((prevErrors) => ({
          ...prevErrors,
          files: "An error occurred while uploading. Please try again.",
        }));
      }
    } else {
      console.error("Validation failed:", errors);
    }
  };

  if (!isOpen) return null;

  // console.log("isss", isModalOpen);
  return (
    <>
      {isOpen && (
        <div className="uploadModal space-y-4">
          <h2 className="text-center mb-4 text-xl font-bold text-green-600">
            {title}
          </h2>

          <button
            onClick={() => setIsModalOpen(false)}
            className="closeBtn absolute top-0 right-4 text-xl font-bold text-gray-800 hover:text-gray-900"
          >
            <IoMdClose />
          </button>

          {/* Success Message */}
          {showSuccess && (
            <p className="text-green-500 text-center mb-4">
              Document uploaded successfully!
            </p>
          )}

          {/* Sub Title Dropdown */}
          <div className="flex flex-col">
            <label htmlFor="subTitle">Sub Title</label>
            <CustomDropdown
              options={subTitleOptions}
              onSelect={handleSubTitleChange}
              className="!w-full"
              value={subTitle}
            />

            {errors.subTitle && (
              <p className="text-red-500 text-sm">{errors.subTitle}</p>
            )}
          </div>

          {/* Year Dropdown */}
          <div className="flex flex-col">
            <label htmlFor="year">Year</label>
            <CustomDropdown
              options={yearOptions}
              onSelect={handleYearChange}
              className="!w-full"
              value={year}
            />

            {errors.year && (
              <p className="text-red-500 text-sm">{errors.year}</p>
            )}
          </div>

          {/* Quarter Dropdown */}
          <div className="flex flex-col">
            <label
              htmlFor="quarter"
              className="block text-base font-medium text-gray-700"
            >
              Quarter
            </label>
            <CustomDropdown
              options={quarterOptions}
              onSelect={handleQuarterChange}
              className="!w-full"
              value={quarter}
            />

            {errors.year && (
              <p className="text-red-500 text-sm">{errors.year}</p>
            )}
          </div>

          {/* Description Input */}
          <div className="flex flex-col">
            <label
              htmlFor="description"
              className="block text-base font-medium text-gray-700"
            >
              Description
            </label>
            <input
              type="text"
              placeholder="Description"
              className="border border-gray-300 rounded px-3 py-2 focus:outline-none w-full"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          {/* Upload Document */}
          <div>
            <label
              htmlFor="uploadFiles"
              className="block text-base font-medium text-gray-700"
            >
              Upload Document
            </label>
            <input
              type="file"
              id="uploadFiles"
              multiple
              accept=".pdf,.xlsx,.xls,.jpeg,.png"
              onChange={handleFileUpload}
              className="border border-gray-300 rounded px-3 py-2 focus:outline-none w-full fileInput"
            />
            {errors.files && (
              <p className="text-red-500 text-sm">{errors.files}</p>
            )}
          </div>

          {/* Display Uploaded File Names */}
          {uploadedFiles.length > 0 && (
            <div className="!mt-0">
              <ul className="">
                {uploadedFiles.map((file, index) => (
                  <li key={index} className="text-green-800 text-sm">
                    {file.name}
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className="flex justify-center">
            <button
              onClick={handleSubmit}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Upload
            </button>
          </div>

          {/* Display the uploaded data if available */}
          {selectedData && (
            <div className="mt-4">
              <h3>Uploaded Data:</h3>
              <pre>{JSON.stringify(selectedData, null, 2)}</pre>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default UploadDocModal;
