export default function ReadMore() {
  return (
    <div className="flex flex-col items-center md:mx-96 mx-8 mt-4 text-gray-600">
      <h2 className="text-xl font-bold border-b-2 w-full pb-2 text-black">
        An Overview
      </h2>
      <p className="text-md mt-4">
        Golden Legand Leasing & Finance Limited is a Mumbai based RBI affiliated
        NBFC listed on the Bombay Stock Exchange, currently involved in digital
        lending and FinTech software development. We boast about ourselves as a
        modern technology company trying to find new solutions and provide
        FinTech based platform to the ever-growing Indian middle class. Our
        company is researching, developing and is involved in complex
        technologies like Artificial Intelligence based financial solutions,
        AI-based Switching and Routing Solutions for e-commerce and banks, cross
        border AI based switching solutions, and global payment routing
        solutions. At GLLFL, we pride ourselves in providing platforms developed
        in the latest technologies like AI, Machine Language, and Java which
        also gives the customer freedom to avail solutions anywhere in the world
        as the platforms are entirely cloud-based.
      </p>
      <p className="text-md mt-4">
        The transition of GLLFL from a conventional lending NBFC to a modern
        technology provider took work. GLLFL changed hands in management in the
        year 2018, and a young and visionary group took over the company's
        reins. With a paid-up capital of 14.87 cr. and net worth of 15 crores as
        of March 31, 2023, GLLFL took the first step towards change in its
        mission by starting its development centre at Techweb Center as IT is
        slowly becoming the backbone of the Global economy. The management of
        GLLFL started Rapid advancements towards developing new Fintech-based
        platforms. Be it payment gateway solutions, Digital lending platforms,
        Debt restructuring apps or AEPS-based platforms, GLLFL is now rapidly
        developing multifaceted payment solutions for all industries and all
        classes of the population. Our development Centre recruits and trains
        experienced and freshers regularly, thus rapidly increasing the skilled
        workforce of developers and engineers. We are incorporating
        technologies, compliances and securities as per the industry, government
        and RBI norms, regularly updated and upgraded by the agencies and the
        Ministry of Finance. Our platforms are PCI-DSS v3.2 secured, thus
        providing the most secure compliance for all digital transactions.
      </p>
      <h2 className="text-xl font-bold border-b-2 mt-4 w-full pb-2 text-black">
        Our Vision
      </h2>
      <p className="text-md mt-4">
        To make Golden Legand Leasing and Finance Limited a corporation that
        will thrive on the principles that “Truth and Transparency will be the
        keystones of our business.”
      </p>
      <p className="text-md mb-4 self-start">
        Our motto for all product development will be “We have Faith in
        Humankind, but AI is here to help.”
      </p>
      <h2 className="text-xl font-bold border-b-2 mt-4 w-full pb-2 text-black">
        Our Mission
      </h2>
      <div className="px-6">
        <h3 className="text-lg font-semibold mt-4 self-start">B2B</h3>
        <p className="text-md mt-4 self-start">
          To provide a comprehensive FinTech solution for new & existing
          businesses, assisting them to thrive in the evolving world of
          e-commerce & its competition.
        </p>
        <h3 className="text-lg font-semibold mt-4 self-start">B2C</h3>
        <p className="text-md mt-4">
          To provide the ultimate and the only secure financial platform to the
          growing Indian middle class to become free from debts incurred due to
          the growing lifestyle expenses.
        </p>
        <h3 className="text-lg font-semibold mt-4 self-start">NBFC</h3>
        <p className="text-md mt-4 mb-8">
          A complete paperless digital lending platform built with one GOAL. To
          provide secured and unsecured low-interest loans for women
          entrepreneurs and small business owners so they can survive the
          growing competition today.
        </p>
      </div>
    </div>
  );
}
