import { useState, lazy, Suspense } from "react";
import BreadCrumb from "../components/BreadCrumb";
const CareerDropdown = lazy(() => import("../components/CareerDropdown"));
const JobCard = lazy(() => import("../components/JobCard"));

export default function Career() {
  const [searchText, setSearchText] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({
    category: "",
    type: "",
    locations: "",
  });

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  return (
    <>
      <BreadCrumb pageName="Career" />
      <div className="flex flex-col md:ms-52 ms-4 mt-4 md:mt-0">
        <h1 className="text-4xl font-semibold">Jobs</h1>
        <div className="flex md:flex-row flex-col my-8">
          <form
            className="me-4 self-center"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="relative">
              <input
                type="search"
                className="block p-2.5 z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 pe-12"
                placeholder="Search Jobs..."
                value={searchText}
                onChange={handleSearch}
                required
              />
              <button
                type="submit"
                className="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-gray-600 rounded-e-lg"
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
                <span className="sr-only">Search</span>
              </button>
            </div>
          </form>
          <Suspense fallback={<div>Loading...</div>}>
            <CareerDropdown
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
          </Suspense>
        </div>
        <Suspense fallback={<div>Loading...</div>}>
          <JobCard searchText={searchText} selectedFilters={selectedFilters} />
        </Suspense>
      </div>
    </>
  );
}
