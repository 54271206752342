import Container from "components/Container";
import BreadCrumb from "../components/BreadCrumb";
import ManagementCard from "../components/ManagementCard";
import { managementData } from "../utils/data/managementData";

export default function KeyManagement() {
  return (
    <>
      <section className="my-8 inner-content">
        <BreadCrumb pageName="Key Management Personnel and Management Team" />
        <Container>
          <h1 className="text-3xl font-extrabold mb-6">
            Key Management Personnel and Management Team
          </h1>

          <h3 className="text-xl font-semibold mb-4">Our esteem Team</h3>
          <p>
            Our team comprises passionate professionals and experts. We believe
            in empowering budding businesses and novice entrepreneurs with
            innovative and creative solutions and the necessary funding. Our
            business model is based on incorporating technology into financial
            services and finding new avenues to change how financial assets are
            managed, created, and exchanged.
          </p>
          <div className="mt-8 flex md:flex-row flex-col flex-wrap justify-center mb-4">
            {managementData.map((data) => (
              <ManagementCard key={data.id} data={data} />
            ))}
          </div>
        </Container>
      </section>
    </>
  );
}
