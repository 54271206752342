import BreadCrumb from "../components/BreadCrumb";
import Container from "../components/Container";

export default function OurStory() {
  return (
    <>
      <BreadCrumb pageName="Our Story" />
      <Container>
        <div className="mb-8 space-y-4">
          <h1 className="text-2xl md:text-3xl font-bold">About Our Firm</h1>
          <p className="mt-4 text-gray-600">
            <span className="font-semibold">
              Golden Legand Leasing & Finance Limited (GLLFL)
            </span>
            is a Mumbai-based, RBI-affiliated Non-Banking Financial Company
            (NBFC) listed on the Bombay Stock Exchange. We specialize in digital
            lending and FinTech software development, positioning ourselves as a
            modern technology company dedicated to finding innovative solutions
            for the growing Indian middle class.
          </p>
          <p className="text-gray-600">
            Our company is at the forefront of research and development in
            complex technologies such as AI-based financial solutions, AI-based
            switching and routing solutions for e-commerce and banks,
            cross-border AI-based switching solutions, and global payment
            routing solutions. We take pride in offering platforms developed
            using cutting-edge technologies like AI, Machine Learning, and Java.
            Our platforms are entirely cloud-based, providing customers with the
            freedom to avail solutions anywhere in the world.
          </p>
          <p className="text-gray-600">
            In 2018, GLLFL underwent a significant transformation when a young
            and visionary group took over the company's management. With a
            paid-up capital of 14.87 cr. and a net worth of 15 crores as of
            March 31, 2023, we embarked on a mission to transition from a
            conventional lending NBFC to a modern technology provider. We have
            since made rapid advancements in developing new-based platforms,
            including payment gateway solutions, digital lending platforms, debt
            restructuring apps, and AEPS-based platforms.
          </p>
          <p className="text-gray-600 mb-12">
            Our vision is to make Golden Legand Leasing and Finance Limited a
            corporation that thrives on the principles of truth and
            transparency. We believe in the power of humankind, but we also
            recognize the immense potential of AI to assist us. We are committed
            to providing user-friendly solutions for consumers to conduct their
            day-to-day digital transactions efficiently and economically.
          </p>
        </div>
      </Container>
    </>
  );
}
