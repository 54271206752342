import Container from "components/Container";

export default function LegalDisclaimer() {
  return (
    <section className="my-8 inner-content">
      <Container>
        <h1 className="font-bold text-3xl">Legal Disclaimer</h1>
        <p className="mt-4">
          Golden Legand Leasing & Finance Limited(GLLFL) retains copyright on
          all the text, contents, graphics and trademarks displayed on this
          site. All the text, graphics and trademarks displayed on this site are
          owned by GLLFL.
        </p>
        <p className="mt-4">
          The information on this site has been included in good faith and is
          for general purpose only and should not be relied upon for any
          specific purpose. The user shall not distribute text or graphics to
          others without the express written consent of GLLFL. The user shall
          also not, without GLLFL’s prior permission, copy and distribute this
          information on any other server, or modify or reuse text or graphics
          on this or any another system.
        </p>
        <p className="mt-4">
          Although GLLFL tries to ensure that all information and
          recommendations, whether in relation to the products, services,
          offerings or otherwise (hereinafter “information”), provided as part
          of this website is correct at the time of inclusion on the web site,
          GLLFL does not guarantee the accuracy of the Information. GLLFL makes
          no representations or warranties as to the completeness or accuracy of
          Information. Certain links in this site connect to other Web Sites
          maintained by third parties over whom GLLFL has no control. GLLFL
          makes no representations as to the accuracy or any other aspect of
          information contained in such other Web Sites.
        </p>
        <p className="mt-4">
          GLLFL hereby disclaims all warranties and conditions with regard to
          this information, including all implied warranties and conditions of
          merchantability, fitness for any particular purpose, title and
          non-infringement. In no event will GLLFL, its related partnerships or
          corporations, or the partners, agents or employees thereof be liable
          for any decision made by the user and/or site visitor for any
          inference or action taken in reliance on the information provided in
          this site or for any consequential, special or similar damages.
          Applicable Law and Jurisdiction of this Disclaimer are governed by and
          to be interpreted in accordance with laws of India, without regard to
          the choice or conflicts of law provisions of any jurisdiction. The
          user/site visitor agrees that in the event of any dispute arising in
          relation to this Disclaimer or any dispute arising in relation to the
          web site whether in contract or tort or otherwise, to submit to the
          jurisdiction of the courts located at Ahmedabad (Gujarat) (India) only
          for the resolution of all such disputes.
        </p>
        <p className="mt-4">
          Except for the historical information herein, statement in this
          website, which includes words or phrases such as “will”, “aim”, “will
          likely result”, “would”, “believe”, “may”, “expect”, “will continue”,
          “anticipate”, “estimate”, “intend”, “plan”, “contemplate”, “seek to “,
          “future”, “objective”, “goal”, “likely”, “project”, “should”,
          “potential”, “will pursue”, and similar expressions or variations of
          such expressions may constitute “forward-looking statements”. These
          forward-looking statements involve a number of risks, uncertainties
          and other factors that could cause actual results to differ materially
          from those suggested by the forward-looking statements. These risks
          and uncertainties include, but are not limited to our liability to
          successfully implement our strategy, our growth and expansion plans,
          obtain regulatory approvals, our provisioning policies, technological
          changes, investment and business income, cash flow projections, our
          exposure to the market risks as well as other risks. The company does
          not undertake any obligation to update forward-looking statements to
          reflect events or circumstances after the date thereof.
        </p>
      </Container>
    </section>
  );
}
