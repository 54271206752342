import { InvestorsCornerProps } from "../helpers/types";
import Container from "./Container";

export default function InvestorsCorner({ title }: InvestorsCornerProps) {
  return (
    <>
      <Container className="px-0">
        <h1 className="text-center sm:text-left text-2xl font-bold mb-4">
          {title}
        </h1>
      </Container>
    </>
  );
}
