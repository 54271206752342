import axios from "axios";
import {
  CareerDropdownItems,
  ClientFormDatas,
  JobData,
  JobFormDatas,
  JobItems,
} from "./types";
const { REACT_APP_API_SERVICE_1 } = process.env;

// export const getJob = async (parsedJobId: number | null): Promise<JobData> => {
//   try {
//     const response = await axios.get<JobData>(
//       `${REACT_APP_API_SERVICE_1}/job/getById/${parsedJobId}`
//     );
//     return response.data;
//   } catch (error) {
//     console.error("There was an error fetching the job data!", error);
//     throw error;
//   }
// };

export const getJob = async (parsedJobId: number | null): Promise<JobData> => {
  try {
    const response = await axios.get<{
      statusCode: number;
      message: string;
      status: string;
      data: JobData; // Adjusted to reflect the structure of `data`
    }>(`${REACT_APP_API_SERVICE_1}/job/getById/${parsedJobId}`);

    return response.data.data; // Extract `data` from the response object
  } catch (error) {
    console.error("There was an error fetching the job data!", error);
    throw error;
  }
};

export const submitApplication = async (
  formData: JobFormDatas,
  jobId: string | number | null
): Promise<string> => {
  const form = new FormData();
  form.append("name", formData.name);
  form.append("email", formData.email);
  form.append("phone", formData.phone);
  form.append("coverLetter", formData.coverLetter);
  if (formData.resume) {
    form.append("resume", formData.resume);
  }
  form.append("job_id", jobId?.toString() || "");

  try {
    const response = await axios.post(
      `${REACT_APP_API_SERVICE_1}/job/apply`,
      form
    );
    return response.data.message;
  } catch (error: any) {
    console.error("There was an error submitting the application:", error);
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);
    } else {
      throw new Error("There was an error submitting the application.");
    }
  }
};

export const fetchJobs = async (
  searchText: string,
  selectedFilters: { category: string; type: string; locations: string }
): Promise<JobItems[]> => {
  try {
    console.log("Fetching jobs from:", `${REACT_APP_API_SERVICE_1}/job/getAll`);

    const params: any = {};
    Object.keys(selectedFilters).forEach((key) => {
      if (selectedFilters[key]) {
        params[key] = selectedFilters[key];
      }
    });
    if (searchText) {
      params.search = searchText;
    }

    // const response = await axios.get<JobItems[]>(
    //   `${REACT_APP_API_SERVICE_1}/job/getAll`,
    //   {
    //     params,
    //   }
    // );

    // Fetch the data from the API
    const response = await axios.get<{
      statusCode: number;
      message: string;
      status: string;
      data: JobItems[];
    }>(
      `${REACT_APP_API_SERVICE_1}/job/getAll`,
      { params } // Include params in the GET request
    );

    return response.data.data;
  } catch (error) {
    console.error("There was an error fetching the job data!", error);
    throw error;
  }
};

export const fetchDropdowns = async (): Promise<CareerDropdownItems[]> => {
  try {
    console.log(
      "Fetching dropdowns from:",
      `${REACT_APP_API_SERVICE_1}/dropdowns`
    );
    // const response = await axios.get<CareerDropdownItems[]>(
    const response = await axios.get<{
      statusCode: number;
      message: string;
      status: string;
      data: CareerDropdownItems[];
    }>(
      `${REACT_APP_API_SERVICE_1}/job/dropdowns`
      // `${process.env.REACT_APP_API_SERVICE_1}/dropdowns`
    );
    return response.data.data;
  } catch (error) {
    console.error("There was an error fetching the dropdown data!", error);
    throw error;
  }
};

export const submitContactForm = async (
  formData: ClientFormDatas
): Promise<string> => {
  try {
    const response = await axios.post(
      `${REACT_APP_API_SERVICE_1}/contact`,
      formData
    );
    return response.data.message;
  } catch (error) {
    console.error("There was an error submitting the form:", error);
    throw error;
  }
};
