import BreadCrumb from "../components/BreadCrumb";
import sideImage from "../utils/images/Banne_600X600_What-we-do.png";

export default function WhatWeDo() {
  return (
    <>
      <BreadCrumb pageName="What We Do" />
      <div className="flex md:flex-row flex-col">
        <img
          src={sideImage}
          alt="sideImage"
          className="mt-5 md:mb-24 rounded-xl md:ms-52 ms-4 w-[400px] md:w-[500px]"
          loading="lazy"
        />
        <div className="flex flex-col ms-12 md:me-80 me-12 text-gray-700">
          <h1 className="text-3xl font-bold mt-10">What We Do</h1>
          <h3 className="text-lg mt-5">
            GLLFL is a trailblazing force, fusing the power of technology and
            financial expertise. We exist to reshape the financial landscape,
            carving a path for the modern Indian middle class. Our fusion of
            digital lending prowess and cutting-edge FinTech solutions fuels
            businesses with the energy to innovate and evolve.
          </h3>
          <h3 className="text-lg mt-5 mb-4">
            The financial sector is undergoing a metamorphosis, and GLLFL stands
            at its forefront. Our expertise in AI-driven financial solutions,
            cross-border payment routing, and the realm of cloud-based platforms
            is revolutionizing how financial services are crafted and
            experienced. We are not just adapting; we are driving change!
          </h3>
        </div>
      </div>
    </>
  );
}
