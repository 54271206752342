// import BreadCrumb from "../components/BreadCrumb";
// import InvestorsCorner from "../components/InvestorsCorner";
// import TabPanel from "../components/TabPanel";
// import shareholdingPatternData from "../utils/data/shareholdingPatternData";

// export default function ShareholdingPattern() {
//   return (
//     <>
//       <BreadCrumb pageName="ShareholdingPattern" />
//       <div className="flex flex-col items-center mb-6">
//         <InvestorsCorner title="Shareholding Pattern" />
//         {/* <TabPanel tabsData={shareholdingPatternData} /> */}
//       </div>
//     </>
//   );
// }

// import { useState, useEffect } from "react";
import BreadCrumb from "../components/BreadCrumb";
import InvestorsCorner from "../components/InvestorsCorner";
import TabPanel from "../components/TabPanel";

import Container from "components/Container";
import { useEffect, useState } from "react";
import UploadDocModal from "components/UploadDocModal";
import { InversterDoc } from "helpers/types";
import { FiUpload } from "react-icons/fi";
import { ShareholdingPatternApiFetch } from "utils/apis";

export default function ShareholdingPattern() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shareholdingPatternDoc, setShareholdingPatternDoc] = useState<
    InversterDoc[]
  >([]);
  const isUser = localStorage.getItem("isLoggedIn") === "true";
  // console.log("isUser ======>", isUser);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  console.log("shareholdingPatternDoc", shareholdingPatternDoc);

  const handleShareholdingPatternDataGetting = async () => {
    const response = await ShareholdingPatternApiFetch(7);
    setShareholdingPatternDoc(response?.data);
  };

  useEffect(() => {
    handleShareholdingPatternDataGetting();
  }, []);

  return (
    <>
      <BreadCrumb pageName="ShareholdingPattern" />
      {/* {console.log("isUser ======>", isUser)} */}
      <Container>
        <div className="flex items-center sm:justify-between flex-col sm:flex-row">
          <InvestorsCorner title="ShareholdingPattern" />
          <div className="flex items-center gap-2">
            {isUser && (
              <button
                onClick={toggleModal}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 w-52 flex items-center gap-4"
              >
                <FiUpload /> Upload Documents
              </button>
            )}
          </div>
        </div>
        <div className="mb-8">
          <TabPanel
            tabsData={shareholdingPatternDoc}
            investorHrefName="shareholdingPattern"
          />
        </div>
      </Container>
      {/* <TabPanel tabsData={announcementData} /> */}

      {/* Upload Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-[#f1f5f9] p-6 relative customShadow rounded-xl w-[400px]">
            <UploadDocModal
              isOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              title="Shareholding Pattern"
              selectedInvestId={7}
              // showApiFetch={AnnouncementApiFetch}
            />
          </div>
        </div>
      )}
    </>
  );
}
