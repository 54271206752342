import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import BreadCrumb from "../components/BreadCrumb";
import Container from "../components/Container";
import companyLogo from "../utils/images/cropped-GLLFL-Logo.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { setLogin, logout, selectIsLoggedIn } from "../redux/authSlice";
import { loginData, logout } from "../redux/authSlice";
import { AppDispatch, RootState } from "redux/store";

interface Errors {
  email?: string;
  password?: string;
}

const CS: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  // const isLoggedIn = useSelector(selectIsLoggedIn); // Use Redux state for login status
  const isLoggedIn =
    useSelector((state: RootState) => state.auth.data?.status === "success") ||
    localStorage.getItem("isLoggedIn") === "true"; // Fallback to localStorage if Redux state is empty

  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errors, setErrors] = useState<Errors>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const navigate = useNavigate();

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const { status, data } = useSelector((state: RootState) => state.auth);

  const validateEmail = (value: string) => {
    let emailError = "";
    if (!value) {
      emailError = "Email is required";
    } else if (!emailRegex.test(value)) {
      emailError = "Please enter a valid email address";
    }
    setErrors((prevErrors) => ({ ...prevErrors, email: emailError }));
  };

  const validatePassword = (value: string) => {
    let passwordError = "";
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const digitRegex = /\d/;
    const specialCharRegex = /[@$!%*?&]/;
    const minLength = 8;

    if (!value) {
      passwordError = "Password is required";
    } else if (value.length < minLength) {
      passwordError = `Password must be at least ${minLength} characters long`;
    } else if (!lowercaseRegex.test(value)) {
      passwordError = "Password must include at least one lowercase letter";
    } else if (!uppercaseRegex.test(value)) {
      passwordError = "Password must include at least one uppercase letter";
    } else if (!digitRegex.test(value)) {
      passwordError = "Password must include at least one number";
    } else if (!specialCharRegex.test(value)) {
      passwordError =
        "Password must include at least one special character (@, $, !, %, *, ?, &)";
    }

    setErrors((prevErrors) => ({ ...prevErrors, password: passwordError }));
  };

  useEffect(() => {
    // console.log("loginData", data);
    // console.log("loginData", status);
    if (data && data?.status === "success") {
      localStorage.setItem("isLoggedIn", "true");
      navigate("/investorlistpage");
    } else if (data?.status === "failed") {
      setIsLoading(false);
      setErrorMessage("Invalid email or password. Please try again.");
      localStorage.setItem("isLoggedIn", "false");
      dispatch(logout()); // Reset Redux state
      console.log(data);
    }
  }, [data, status, navigate, dispatch]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!email || !password || errors.email || errors.password) {
      return;
    }

    setIsLoading(true); // Show loading state
    setErrorMessage(""); // Reset any previous error messages

    dispatch(loginData({ email, password })); // Set Redux state to logged in

    // localStorage.setItem("isLoggedIn", "true");

    // Local testing credentials start
    // const localEmail = "admin@example.com";
    // const localPassword = "Password123$";

    // // Check if entered credentials match the hardcoded ones
    // if (email === localEmail && password === localPassword) {
    //   localStorage.setItem("isLoggedIn", "true"); // Save login state to local storage
    //   dispatch(loginData({ email, password })); // Update Redux state
    //   toast.success("Login successful! (Local Testing)"); // Show success toast
    //   navigate("/investorlistpage"); // Redirect to investor list page
    //   return;
    // } else {
    //   // If credentials don't match
    //   toast.error("Invalid email or password. Please try again.");
    //   setErrorMessage("Invalid email or password. Please try again.");
    // }

    // Local testing credentials end
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);
    validatePassword(value);
  };

  const handleLogout = () => {
    localStorage.setItem("isLoggedIn", "false");
    dispatch(logout()); // Dispatch logout action to Redux
    navigate("/"); // Redirect to the login page
  };

  return (
    <div className="payEmi-wrapper">
      <BreadCrumb pageName="Login" />
      <div className="w-full h-full flex justify-center items-center pt-0 p-10 md:pr-30 relative">
        <div className="py-8 px-0 md:px-6 w-full md:w-[600px] bg-white bg-opacity-90 min-h-[350px] rounded-lg shadow-lg backdrop-blur-xl backdrop-filter flex items-center justify-center flex-col">
          <Container>
            <div className="logo mb-4 flex justify-center">
              <img src={companyLogo} alt="logo" width={200} loading="lazy" />
            </div>

            {isLoggedIn ? (
              <div className="w-full flex justify-between items-center">
                <span className="text-sm font-semibold">You are logged in</span>
                <button
                  onClick={handleLogout}
                  className="py-2 px-4 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
                >
                  Logout
                </button>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="w-full">
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-semibold mb-2"
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    id="email"
                    value={email}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setEmail(e.target.value);
                      validateEmail(e.target.value);
                    }}
                    placeholder="Enter your email"
                    className={`w-full px-4 py-2 border ${
                      errors.email ? "border-red-600" : "border-gray-300"
                    } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500`}
                  />
                  {errors.email && (
                    <p className="text-red-500 text-xs mt-2">{errors.email}</p>
                  )}
                </div>

                <div className="mb-6 relative">
                  <label
                    htmlFor="password"
                    className="block text-sm font-semibold mb-2"
                  >
                    Password
                  </label>
                  <input
                    type={passwordVisible ? "text" : "password"}
                    id="password"
                    value={password}
                    onChange={handlePasswordChange}
                    placeholder="Enter your password"
                    className={`w-full px-4 py-2 border ${
                      errors.password ? "border-red-500" : "border-gray-300"
                    } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500`}
                  />
                  <div
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    className="absolute right-4 top-12 transform -translate-y-1/2 cursor-pointer"
                  >
                    {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                  </div>
                  {errors.password && (
                    <p className="text-red-500 text-xs mt-2">
                      {errors.password}
                    </p>
                  )}
                </div>

                <button
                  type="submit"
                  className="w-full py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  disabled={isLoading}
                >
                  {isLoading ? "Logging in..." : "Log In"}
                </button>

                {errorMessage && (
                  <p className="text-red-500 text-xs mt-2">{errorMessage}</p>
                )}
              </form>
            )}
          </Container>
        </div>
      </div>

      <ToastContainer className="flex m-auto top-24 left-0 right-0 absolute" />
    </div>
  );
};

export default CS;
