import { ReportData, TransformedData } from "./types";

export interface DataItem {
  id: number;
  investId: string;
  title: string;
  subtitle: string;
  description: string;
  quarter: string;
  years: string;
  status: string;
  fileUrl: string;
  createdAt: string;
  updatedAt: string;
}

interface Report {
  id: number;
  investId: string;
  title: string;
  subtitle: string;
  description: string;
  quarter: string;
  years: string;
  fileUrl: string;
  createdAt: string;
  updatedAt: string;
  status: string;
}

interface QuarterlyReport {
  label: string;
  reports: { label: string; file: string }[];
}

interface FiscalYear {
  title: string;
  quarters: QuarterlyReport[];
}

// Handle File URL transformation
// const handleFileUrl = (url: string) => {
const handleFileUrl = (url: string): string => {
  if (url.includes("-")) {
    const arr = url.split("-");
    const fileName = arr[arr.length - 1];
    return fileName;
  }
  // else {
  return "NA";
  // }
};

// Sorting Data in Ascending Order based on year this for non accordion item Data
// const handleSortingData = (data: DataItem[]): DataItem[] => {
// const handleSortingData = (data: DataItem[] = []): DataItem[] => {
//   console.log("Sorting data:", data);
//   return [...data].sort((a, b) => {
//     const yearA = parseInt(a.years.split("-")[0], 10); // Extract start year
//     const yearB = parseInt(b.years.split("-")[0], 10); // Extract start year
//     return yearA - yearB; // Ascending order
//   });
// };

// Sorting Data in Dscending Order based on year this for non accordion item Data
// const handleSortingData = (data: DataItem[]): DataItem[] => {
const handleSortingData = (data: DataItem[] = []): DataItem[] => {
  console.log("Sorting data:", data);
  return [...data].sort((b, a) => {
    const yearA = parseInt(a.title.split(" ")[1].replace("-", ""));
    const yearB = parseInt(b.title.split(" ")[1].replace("-", ""));
    return yearB - yearA;
  });
};

// const extractSubtitles = (data: DataItem[]): string[] => {
const extractSubtitles = (data: DataItem[] = []): string[] => {
  console.log("Extracting subtitles from:", data);
  // const result = data.map((item) => item.subtitle);
  const result = data.map((item) => item.subtitle || ""); // Avoid undefined subtitles
  return Array.from(new Set(result));
};

// function transformData(data: DataItem[]) {
function transformData(data: DataItem[] = []): FiscalYear[] {
  console.log("Transforming data:", data);
  if (!data.length) return []; // Handle empty or undefined data

  // Grouping data by fiscal year
  const groupedData = data.reduce((acc, item) => {
    const yearKey = `F.Y. ${item.years}`;
    const quarterKey = item.quarter;

    // Initialize year if it doesn't exist
    if (!acc[yearKey]) {
      acc[yearKey] = { title: yearKey, quarters: [] };
    }

    // Find or initialize the quarter
    let quarter = acc[yearKey].quarters.find((q) => q.label === quarterKey);
    if (!quarter) {
      quarter = { label: quarterKey, reports: [] };
      acc[yearKey].quarters.push(quarter);
    }

    // Add the report to the quarter
    quarter.reports.push({
      label: item.title,
      file: `/assets/Investors-Corner/Announcement/${item.years}/${item.title
        .replace(/ /g, "-")
        .replace(/[()]/g, "")
        .toLowerCase()}.pdf`,
    });

    return acc;
  }, {} as { [key: string]: FiscalYear });

  // Convert grouped data to an array
  return Object.values(groupedData);
}

// function transformDataToObject(data: ReportData[]): TransformedData {
function transformDataToObject(data: ReportData[] = []): TransformedData {
  // Grouping data by fiscal year
  const groupedData: TransformedData = data.reduce((acc, item) => {
    const yearKey = `F.Y. ${item.years}`;
    const quarterKey = item.quarter;

    // Initialize year if it doesn't exist
    if (!acc[yearKey]) {
      acc[yearKey] = { title: yearKey, quarters: [] };
    }

    // Find or initialize the quarter
    let quarter = acc[yearKey].quarters.find((q) => q.label === quarterKey);
    if (!quarter) {
      quarter = { label: quarterKey, reports: [] };
      acc[yearKey].quarters.push(quarter);
    }

    // Add the report to the quarter
    quarter.reports.push({
      label: item.title,
      file: `/assets/Investors-Corner/Announcement/${item.years}/${item.title
        .replace(/ /g, "-")
        .replace(/[()]/g, "")
        .toLowerCase()}.pdf`,
    });

    return acc;
  }, {} as TransformedData);

  return groupedData;
}

// Group reports by fiscal year
// function groupReportsByFiscalYear(reports: Report[]): FiscalYear[] {
function groupReportsByFiscalYear(reports: Report[] = []): FiscalYear[] {
  console.log("Grouping reports:", reports);
  if (!reports.length) return []; // Handle empty or undefined reports

  const groupedReports: { [key: string]: FiscalYear } = {};

  reports.forEach((report) => {
    const fiscalYear = `F.Y. ${report.years}`;
    if (!groupedReports[fiscalYear]) {
      groupedReports[fiscalYear] = { title: fiscalYear, quarters: [] };
    }

    let quarter = groupedReports[fiscalYear].quarters.find(
      (q) => q.label === report.quarter
    );
    if (!quarter) {
      quarter = { label: report.quarter, reports: [] };
      groupedReports[fiscalYear].quarters.push(quarter);
    }

    quarter.reports.push({
      label: report.title,
      // file: report.fileUrl,
      file: report.fileUrl || "NA", // Default to "NA" if fileUrl is missing
    });
  });

  // {*Sort fiscal years in ascending order this is old code start*}
  // return (
  //   Object.values(groupedReports)

  //     .sort((a, b) => a.title.localeCompare(b.title))
  //     .map((fiscalYear) => ({
  //       ...fiscalYear,
  //       quarters: fiscalYear.quarters
  //         .sort((a, b) => a.label.localeCompare(a.label))
  //         .map((quarter) => ({
  //           ...quarter,
  //           reports: quarter.reports.sort((a, b) =>
  //             a.label.localeCompare(b.label)
  //           ),
  //         })),
  //     }))
  // );
  // {*Sort fiscal years in ascending order this is old code end*}

  // {*Sort fiscal years in ascending order this is new code start*}
  // return Object.values(groupedReports)
  //   .sort((a, b) => {
  //     // Sort fiscal years in ascending order
  //     const yearA = parseInt(a.title.split(" ")[1].replace("-", ""));
  //     const yearB = parseInt(b.title.split(" ")[1].replace("-", ""));
  //     return yearA - yearB; // Change to ascending order
  //   })
  //   .map((fiscalYear) => ({
  //     ...fiscalYear,
  //     quarters: fiscalYear.quarters
  //       .sort((a, b) => a.label.localeCompare(b.label))
  //       .map((quarter) => ({
  //         ...quarter,
  //         reports: quarter.reports.sort((a, b) =>
  //           a.label.localeCompare(b.label)
  //         ),
  //       })),
  //   }));
  // {*Sort fiscal years in ascending order this is new code end*}

  return Object.values(groupedReports)
    .sort((a, b) => {
      // Sort fiscal years in descending order
      const yearA = parseInt(a.title.split(" ")[1].replace("-", ""));
      const yearB = parseInt(b.title.split(" ")[1].replace("-", ""));
      return yearB - yearA;
    })
    .map((fiscalYear) => ({
      ...fiscalYear,
      quarters: fiscalYear.quarters
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((quarter) => ({
          ...quarter,
          reports: quarter.reports.sort((a, b) =>
            a.label.localeCompare(b.label)
          ),
        })),
    }));
}

export {
  handleFileUrl,
  handleSortingData,
  extractSubtitles,
  transformData,
  transformDataToObject,
  groupReportsByFiscalYear,
};
