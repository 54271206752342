import BreadCrumb from "../components/BreadCrumb";
import bgImage from "../utils/images/Mission-VisionBg-1.png";
import sideImage from "../utils/images/Banner_600X600_Vision-mission.png";
import Container from "../components/Container";

export default function VisionMission() {
  return (
    <>
      <div
        className="bg-fixed bg-cover bg-no-repeat bg-top-4"
        style={{ backgroundImage: `url(${bgImage})`, height: "25rem" }}
      >
        <h1 className="text-5xl text-center font-semibold text-white pt-44">
          Mission & Vision
        </h1>
      </div>
      <BreadCrumb pageName="Mission & Vision" />
      <Container>
        <div className="sm:flex gap-8 lg:gap-16">
          <div className="w-full md:w-2/5">
            <img
              src={sideImage}
              alt="sideImage"
              className="md:mb-24 rounded-xl"
              loading="lazy"
            />
          </div>

          <div className="w-full md:w-3/5">
            <h2 className="text-2xl lg:text-3xl font-bold mt-4">Mission :</h2>
            <div className="px-6">
              <h3 className="text-xl font-semibold mt-4 text-black">B2B</h3>
              <p className="mt-4 text-gray-600">
                To provide a comprehensive FinTech solution for new & existing
                businesses, assisting them to thrive in the evolving world of
                e-commerce & its competition.
              </p>
              <h3 className="text-xl font-semibold mt-4 text-black">B2C</h3>
              <p className="mt-4 text-gray-600 mb-4">
                To provide the ultimate and the only secure financial platform
                to the growing Indian middle class to become free from debts
                incurred due to the growing lifestyle expenses
              </p>
              <h3 className="text-xl font-semibold mt-4 text-black">NBFC</h3>
              <p className="mt-4 text-gray-600 mb-4">
                A complete paperless digital lending platform built with one
                GOAL. To provide secured and unsecured low-interest loans for
                women entrepreneurs and small business owners so they can
                survive the growing competition today.
              </p>
              <h3 className="text-xl font-semibold mt-4 text-black">NBFC</h3>
              <p className="mt-4 text-gray-600 mb-4">
                A complete paperless digital lending platform built with one
                GOAL. To provide secured and unsecured low-interest loans for
                women entrepreneurs and small business owners so they can
                survive the growing competition today.
              </p>
            </div>

            <h2 className="text-xl lg:text-3xl font-bold mt-4">Vision :</h2>
            <p className="mt-4 text-gray-600 mb-4">
              To make Golden Legand Leasing and Finance Limited a corporation
              that will thrive on the principles that “Truth and Transparency
              will be the keystones of our business.”
            </p>
            <p className="mt-4 text-gray-600 mb-4">
              Our motto for all product development will be “We have Faith in
              Humankind, but AI is here to help.”
            </p>
          </div>
        </div>
      </Container>
    </>
  );
}
